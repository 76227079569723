import React from 'react';
import './stylesheets/Footer.scss';

function Footer() {
  return (
    <footer>
      Copyright ©️ 2022
      <a href="https://github.com/mavericks-db">mavericks-db</a>
    </footer>
  );
}

export default Footer;
